/**
 * @description axios的二次封装
 */

import axios from "axios"
import {
    Promise
} from "core-js"
import qs from "qs"


/* 
    根据环境变量区分接口默认地址
*/
// console.log(222, process.env.NODE_ENV)
switch (process.env.NODE_ENV) {
    case "production":
        axios.defaults.baseURL = "https://mangshoudao.com/msd"
        // axios.defaults.baseURL = "http://192.168.2.226:8088/msd" // 门福通
        break
    case "development":
        // axios.defaults.baseURL = "http://192.168.2.144:82"  // 周坤
        // axios.defaults.baseURL = "http://192.168.2.226:8088/msd" // 门福通
        axios.defaults.baseURL = "https://mangshoudao.com/msd"
        break
    default:
        axios.defaults.baseURL = "https://mangshoudao.com/msd"
        // axios.defaults.baseURL = "http://192.168.2.226:8088/msd" // 门福通
}


/* 
    设置超时时间和跨域是否允许携带凭证
*/
axios.defaults.timeout = 10000 // 设置超时时间
axios.defaults.withCredentials = true // 跨域是否允许携带凭证


/* 
    设置请求传递数据的格式
    x-www-form-urlencoded
*/
axios.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded"
// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.transformRequest = data => qs.stringify(data)


/* 
    设置请求拦截器
    客户端发送请求 =》 [请求拦截器] =》 服务器
    TOKEN校验(jwt) ： 接收服务器返回的token，存储到vuex或者本地存储中，
    每一次向服务器发送请求，我们应该把token带上
*/
axios.interceptors.request.use((config) => {
    // 携带上token
    let token = window.sessionStorage.getItem("token")
    token && (config.headers.Authorization = token)
    // 'Content-type': 'application/json;charset=UTF-8'
    // 请求添加时间戳避免缓存
    if (config.method === 'post') {
        config.data = {
            _t: Date.parse(new Date()) / 1000,
            ...config.data
        }
    } else if (config.method === 'get') {
        config.params = {
            _t: Date.parse(new Date()) / 1000,
            ...config.params
        }
    }

    return config
}, error => {
    return Promise.reject(error)
})


/* 
    设置响应拦截器
    服务器返回信息 =》  [拦截的处理] =》 客户端收取到信息
*/
// axios.defaults.validateStatus = status =>{
//     return /^(2|3)\d{2}$/.test(status)
// }
axios.interceptors.response.use(res => {
    // console.log("res", res)
    return res.data
}, err => {
    // console.log("err", err)
    let {
        response
    } = err
    // console.log("response", response)
    // Message({
    //     type: "error",
    //     message: "响应失败"
    // })
    if (response) {
        // => 服务器返回结果
        switch (response.status) {
            case 401: // 当前请求需要用户验证（一般为未登录
                break
            case 403: // 服务器已经理解请求，但是拒绝执行它（一般为token过期
                break
            case 404: // 请求失败，请求所希望得到的资源未被在服务器上发现
                break
        }

    } else {
        // => 服务器没有返回结果
        if (!window.navigator.onLine) {
            // 断网处理，跳转到断网页面
            return
        }
        return Promise.reject(err)
    }
})


// 导出
export default axios
