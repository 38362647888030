<!--
* @FileDescription: 查询表单
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-query">
    <!-- 表单 -->
    <el-form
      :inline="true"
      :model="queryForm"
      ref="form"
      :rules="queryFormRules"
      class="form"
    >
      <el-form-item
        v-for="(item, index) in queryFormItemList"
        :key="index"
        :label="item.label"
        :prop="item.prop"
      >
        <!-- input -->
        <el-input
          v-if="item.type === 'input'"
          v-model.trim="queryForm[item.prop]"
          :placeholder="item.placeholder"
          :clearable="item.clearable || true"
          :disablede="item.disablede || false"
          :size="item.size || 'small'"
        ></el-input>
        <!-- select -->
        <el-select
          v-if="item.type === 'select'"
          v-model="queryForm[item.prop]"
          :disabled="item.disabled || false"
          :size="item.size || 'small'"
        >
          <el-option
            v-for="op in item.options"
            :label="op.name"
            :value="op.id"
            :key="op.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <!-- <el-button
          v-if="isReset"
          icon="el-icon-refresh"
          type="primary"
          size="small"
          @click="reset('queryForm')"
          >重置</el-button
        > -->
        <el-button
          v-if="isQuery"
          icon="el-icon-search"
          type="primary"
          size="small"
          @click="query"
          >查询</el-button
        >
        <el-button
          v-if="isAdd"
          icon="el-icon-plus"
          type="primary"
          size="small"
          @click="add"
          >添加</el-button
        >
        <el-button
          v-if="isExport"
          icon="el-icon-download"
          type="primary"
          size="small"
          @click="_export"
          >导出(不选导出全部)
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "CommonQueryDemo",
  props: {
    // 表单
    queryForm: {
      type: Object,
      default: () => {
        return {}
      },
    },

    // 表单项
    queryFormItemList: {
      type: Array,
      default: () => {
        return []
      },
    },

    // 查询表单校验
    queryFormRules: {
      type: Object,
      default: () => {
        return {}
      },
    },

    // 是否重置
    isReset: {
      type: Boolean,
      default: true,
    },

    // 是否查询
    isQuery: {
      type: Boolean,
      default: true,
    },

    // 是否添加
    isAdd: {
      type: Boolean,
      default: true,
    },

    // 是否导出
    isExport: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },

  methods: {
    // 重置
    reset() {
      for (const key in this.queryForm) {
        this.queryForm[key] = ""
      }
      this.$emit("_reset", this.queryForm)
    },

    // 查询
    query() {
      this.$emit("_query", this.queryForm)
    },

    // 添加
    add() {
      this.$emit("_add", this.queryForm)
    },

    // 导出
    _export() {
      this.$emit("_export", this.queryForm)
    },
  },
}
</script>

<style lang="scss" scoped>
.common-query {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .form {
    flex: 1;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

// input icon 样式
::v-deep .el-input__icon {
  color: #c0c4cc !important;
}
</style>