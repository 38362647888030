<!--
* @FileDescription: 基于el-table二次封装
* @Author: chenz
* @Date: 2021/5/13
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-table">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      :stripe="isStripe"
      :border="isBorder"
      :max-height="maxHeight"
      :default-sort="defaultSort"
      :row-class-name="tableRowClassName"
      :header-cell-style="headerCellStyle"
      :highlight-current-row="highlightCurrentRow"
      v-loading="loading"
      element-loading-text="正在加载中..."
      element-loading-spinner="el-icon-loading"
      @sort-change="sortChange"
      @current-change="rowChange"
      @selection-change="handleSelectionChange"
      style="width: 100%"
    >
      <!-- 多选 -->
      <el-table-column
        v-if="isSelection"
        type="selection"
        width="80"
        fixed="left"
        align="center"
      >
      </el-table-column>
      <!-- 序号 -->
      <el-table-column
        v-if="isIndex"
        type="index"
        width="80"
        label="序号"
        align="center"
      >
      </el-table-column>
      <!-- 每列 -->
      <template v-for="(item, index) in columns">
        <!-- 一般为操作列 或者为 自定义列 -->
        <slot v-if="item.slot && !isButtons" :name="item.slot"></slot>
        <!-- 普通列 -->
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :fixed="item.fixed"
          :align="item.align || 'center'"
          :sortable="item.sortable"
          :min-width="item.minWidth"
          :formatter="item.formatter"
          :show-overflow-tooltip="item.showOverFlowTooltip"
        >
          <template v-if="!item.type" slot-scope="scope">
            <template v-if="item.prop == 'sex'">
              {{ formatter(scope.row, scope.row[item.prop]) }}
            </template>
            <template v-else-if="item.prop == 'type'">
              {{ scope.row[item.prop]  | cardType }}
            </template>
            <template v-else-if="item.prop !== 'image'">
              {{ scope.row[item.prop] || "暂无" }}
            </template>
            <template>
              <img
                v-if="item.prop === 'image'"
                :src="scope.row[item.prop]"
                width="80"
                height="80"
                class=""
              />
            </template>
          </template>
        </el-table-column>
      </template>
      <!-- 默认操作列 -->
      <el-table-column
        v-if="isButtons"
        label="操作"
        :width="150"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="edit(scope.row)" v-if="isEdit"
            >修改</el-button
          >
          <el-button type="danger" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="paging">
      <el-pagination
        v-if="pagingOptions"
        :pagingOptions="pagingOptions"
        :current-page="pagingOptions.page"
        :page-size="pagingOptions.limit"
        :page-sizes="pagingOptions.pageSizes"
        :total="pagingOptions.total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>


<script>
export default {
  // 接收的参数
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => {
        return []
      },
    },

    /**
     * @description
     *  @param {Array} columns 表头列
     *  @param {String} type 类型
     *  @param {String} prop 字段
     *  @param {String} label 名称
     *  @param {Number} width 列宽
     *  @param {String} fixed 固定
     *  @param {String} width 列宽
     *  @param {String} align 对齐
     *  @param {String} minWidth 最小列宽
     *  @param {Function} formatter 格式化内容
     *  @param {String | Boolean} sortable 排序
     *  @param {Boolean} showOverFlowTooltip 文本过长tooltip
     */
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },

    // 加载
    loading: {
      type: Boolean,
      default: false,
    },

    // 是否斑马纹
    isStripe: {
      type: Boolean,
      default: true,
    },

    // 是否边框
    isBorder: {
      type: Boolean,
      default: true,
    },

    // 是否导出
    isSelection: {
      type: Boolean,
      default: true,
    },

    // 是否序号
    isIndex: {
      type: Boolean,
      default: true,
    },

    // 是否操作
    isButtons: {
      type: Boolean,
      default: true,
    },

    // 是否操作
    isEdit: {
      type: Boolean,
      default: true,
    },

    // 高度
    maxHeight: {
      type: Number,
      default: 700,
    },

    // 默认排序
    defaultSort: {
      type: Object,
      default: () => {
        return {}
      },
    },

    // 表头单元格设置一样的 Style
    headerCellStyle: {
      type: Object,
      default: () => {
        return {}
      },
    },

    // 属性即可实现单选
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },

    // 分页配置
    pagingOptions: {
      type: Object,
      default: () => {
        return {
          page: 1, // 当前页
          pageSizes: [10, 20, 30, 50, 100], // 可选择的每页显示多少条
          limit: 10, // 每页显示多少条
          total: 100, // 总条数
          pathUrl: "",
        }
      },
    },
  },
  // filters: {
  //   sexFilter(i，) {
  //     let sexStatus = {
  //       0: "女",
  //       1: "男",
  //     }
  //     return sexStatus[i]
  //   },
  // },
  data() {
    return {}
  },

  methods: {
    formatter(row, column) {
      // console.log("aaa", row, column)
      if (row.sex === 0) {
        return "女"
      } else if (row.sex === 1) {
        return "男"
      }
    },

    formatter2(row, column) {
      // console.log("aaa", row, column)
      if (row.type === 0) {
        return "现金储值卡"
      } else if (row.type === 1) {
        return "项目储值卡"
      }
    },
    /**
     * @description 状态行
     *  @param {参数类型} 参数名称
     * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
     */
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row"
      } else if (rowIndex === 3) {
        return "success-row"
      }
      return ""
    },

    /**
     * @description 选中时触发的事件
     *  @param {Object} val 当前行信息
     */
    rowChange(val) {
      this.$emit("_rowChange", val)
    },

    /**
     * @description 多选事件
     *  @param {Array} val 选择的项
     */
    handleSelectionChange(val) {
      // console.log('handleSelectionChange',val)
      this.$emit("_handleSelectionChange", val)
    },

    /**
     * @description 排序事件
     *  @param {Object} sort 排序字段
     * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
     */
    sortChange(sort) {
      this.$emit("_sortChange", sort)
    },

    /**
     * @description 每页多少条事件
     *  @param {Number} val 多少条
     * @return 返回多少条
     */
    handleSizeChange(val) {
      this.$emit("_sizeChange", val)
    },

    /**
     * @description 当前页事件
     *  @param {Number} val 多少条
     * @return 返回多少条
     */
    handleCurrentChange(val) {
      this.$emit("_currentChange", val)
    },

    // 修改
    edit(row) {
      this.$emit("_edit", row)
    },

    // 删除
    del(row) {
      this.$emit("_del", row)
    },
  },
}
</script>


<style lang="scss" scoped>
</style>