import Vue from 'vue'
import VueRouter from 'vue-router'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)



const routes = [{
        path: '/',
        redirect: '/login',
        name: 'Login',
        component: () => import('../views/login')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            title: '首页',
        },
        component: () => import('../views/home'),
        redirect: '/home/user-manage', // 首页重定向
        children: [
            // {
            //     path: '/home/banner-manage',
            //     name: 'BannerManage',
            //     component: () => import('../views/banner-manage')
            // },
            {
                path: '/home/user-manage',
                name: 'UserManage',
                meta: {
                    title: '用户管理',
                },
                component: () => import('../views/user-manage')
            },
            {
                path: '/home/member-manage',
                name: 'UserManage',
                meta: {
                    title: '会员管理',
                },
                component: () => import('../views/member-manage')
            },
            {
                path: '/home/store-manage',
                name: 'StoreManage',
                meta: {
                    title: '门店管理',
                },
                component: () => import('../views/store-manage')
            },
            {
                path: '/home/order-manage',
                name: 'OrderManage',
                meta: {
                    title: '订单管理',
                },
                component: () => import('../views/order-manage')
            },
            {
                path: '/home/meituan-data-manage',
                name: 'MeituanDataManage',
                meta: {
                    title: '美团数据管理',
                },
                component: () => import('../views/meituan-data-manage')
            }, 
            {
                path: '/home/massagist-manage',
                name: 'MassagistManage',
                meta: {
                    title: '技师管理',
                },
                component: () => import('../views/massagist-manage')
            },
            {
                path: '/home/service-items-manage',
                name: 'ServiceItemsManage',
                meta: {
                    title: '项目管理',
                },
                component: () => import('../views/service-items-manage')
            },
            {
                path: '/home/project-classify-manage',
                name: 'ProjectClassifyManage',
                meta: {
                    title: '项目分类管理',
                },
                component: () => import('../views/project-classify-manage')
            },
            // {
            //     path: '/home/integral-manage',
            //     name: 'IntegralManage',
            //     component: () => import('../views/integral-manage')
            // },
            // {
            //     path: '/home/online-Customer-service',
            //     name: 'OnlineCustomerService',
            //     component: () => import('../views/online-Customer-service')
            // },
            {
                path: '/home/evaluate-manage',
                name: 'EvaluateManage',
                meta: {
                    title: '评价管理',
                },
                component: () => import('../views/evaluate-manage')
            },
            {
                path: '/home/tape-manage',
                name: 'TapeManage',
                meta: {
                    title: '录音管理',
                },
                component: () => import('../views/tape-manage')
            },
            {
                path: '/home/banner-manage',
                name: 'HomeBannerManage',
                meta: {
                    title: '首页banner管理',
                },
                component: () => import('../views/banner-manage')
            },
            {
                path: '/home/about-us',
                name: 'AboutUs',
                meta: {
                    title: '关于我们',
                },
                component: () => import('../views/about-us')
            },
            // {
            //     path: '/home/marketing-servmanageice',
            //     name: 'MarketingServmanageice',
            //     component: () => import('../views/marketing-servmanageice')
            // },
        ]
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === "/login") {
        next()
    } else {
        const token = window.sessionStorage.getItem("token")
        if (!token) {
            next("/login")
        } else {
            next()
        }
    }
})


export default router