<!--
* @FileDescription: 弹框表单
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-form">
    <!-- 弹框 -->
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :before-close="handleClose"
      width="60%"
    >
      <!-- 表单 -->
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 每一项 -->
        <el-form-item
          v-for="(item, index) in formItemList"
          :key="index"
          :label="item.label"
          :prop="item.prop"
        >
          <!-- 输入框 -->
          <el-input
            size="small"
            clearable
            v-if="item.type === 'input' || item.type === 'textarea'"
            v-model.trim="form[item.value]"
            :type="item.type"
            :disabled="item.disabled || false"
            :placeholder="item.placeholder"
          ></el-input>

          <el-input
            v-if="item.type === 'input-number'"
            size="small"
            clearable
            v-model.trim="form[item.value]"
            :disabled="item.disabled || false"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            maxlength="8"
            :placeholder="item.placeholder"
          ></el-input>

          <!-- 金额输入框 -->
          <!-- <el-input
            size="small"
            clearable
            v-if="item.type === 'input' || item.type === 'textarea'"
            ref="input"
            :min="item.curMin"
            :max="item.curMax"
            :controls="false"
            v-model.trim="form[item.value]"
            @change="handleChange"
            @blur="handleBlur"
            @focus="focusInput"
            :class="clz"
            :disabled="item.disabled || false"
            :placeholder="item.placeholder"
            :precision="precision"
          ></el-input> -->
          <!-- select -->
          <!-- :filter-method="handleFilter" -->
          <el-select
            v-if="item.type === 'select'"
            v-model.trim="form[item.value]"
            size="small"
            width="100%"
            clearable
            :placeholder="item.placeholder"
            :multiple="item.multiple || false"
            :filterable="item.filterable || false"
            @change="handleSelectChange($event, item)"
            :disabled="item.disabled || false"
          >
            <el-option
              v-for="(ite, ind) in item.options"
              :key="ind"
              :label="ite.name || ite.classifyName"
              :value="ite.id"
            >
            </el-option>
          </el-select>
          <!-- 日期 -->
          <el-date-picker
            size="small"
            clearable
            v-if="item.type === 'date'"
            v-model="form[item.value]"
            :type="item.type"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择生日日期"
          >
          </el-date-picker>
          <!-- 单选组 -->
          <el-radio-group
            size="small"
            v-if="item.type === 'el-radio-group'"
            v-model="form[item.value]"
          >
            <el-radio
              v-for="(item2, index2) in item.radioGroupList"
              :key="index2"
              :label="item2.label"
              >{{ item2.text }}</el-radio
            >
          </el-radio-group>
          <!-- 上传头像 -->
          <el-upload
            v-if="item.type === 'uploadAvatar'"
            ref="uploadAvatar"
            class="avatar-uploader"
            :action="url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="form[item.value]"
              :src="form[item.value]"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- 上传头像 -->
          <el-upload
            v-if="item.type === 'uploadLogo'"
            ref="uploadLogo"
            class="avatar-uploader"
            :action="url"
            :show-file-list="false"
            :on-success="handleLogoSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="form[item.value]"
              :src="form[item.value]"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <!-- upload list -->
          <el-upload
            v-if="item.type === 'uploadPhotoList'"
            class="upload-demo"
            list-type="picture"
            :action="url"
            :file-list="item.fileList"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :limit="item.limit"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，照片个数为{{ item.limit }}张且不超过500kb
            </div>
          </el-upload>  
          <!-- cascader 省市区 -->
          <template
            v-if="item.type === 'cascader' && item.description === '省市区'"
          >
            <el-row>
              <el-col :span="12" style="padding-right: 20px">
                <el-cascader
                  v-model="form[item.value]"
                  :options="options"
                  :size="item.size || 'large'"
                  style="width: 100%"
                  clearable
                  filterable
                  @change="cascaderChange"
                >
                </el-cascader
              ></el-col>
              <el-col :span="12">
                <el-input
                  v-model.trim="form[item.value2]"
                  :size="item.size || 'large'"
                  placeholder="请输入详细的地址"
                  clearable
                ></el-input
              ></el-col>
            </el-row>
          </template>
        </el-form-item>
        <!-- buttons -->
        <el-form-item>
          <div class="buttons">
            <el-button
              type="primary"
              size="small"
              class="flex-button"
              @click="confirm('form')"
              >确定</el-button
            >
            <el-button
              type=""
              size="small"
              class="flex-button"
              @click="cancel('form')"
              >取消</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import http from "../../api/http"

// 引入el-cascader 省市区组件
import { regionData, CodeToText } from "element-china-area-data"

export default {
  name: "CommonForm",
  props: {
    // 弹框标题
    title: String,

    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },

    // 弹框表单
    form: {
      type: Object,
      default: () => {
        return {}
      },
    },

    /**
     * @description
     *  @param {Array} formItemList 表单内需要哪些表单控件
     *  @param {String} type 类型
     *  @param {String} prop 字段
     *  @param {String} label 名称
     *  @param {Array}} options 下拉框数据
     */
    formItemList: {
      type: Array,
      default: () => {
        return {}
      },
    },

    // 弹框校验
    rules: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      url: null,

      // 全国省市区数据
      options: regionData,
    }
  },

  mounted() {
    this.NODE_ENV = process.env.NODE_ENV === "development"
    // const baseURL = http.defaults.baseURL
    const baseURL = http.defaults.baseURL
    this.url = `${baseURL}/api/user/store/doUpload`
  },

  methods: {
    // 关闭dialog前的回调
    handleClose(form) {
      this.$emit("_Close", form)
    },

    // 取消
    cancel(form) {
      this.$emit("_cancel", form)
    },

    // 确定
    confirm(form) {
      this.$emit("_confirm", form)
    },

    // 上传头像
    handleAvatarSuccess(res, file) {
      this.$emit("handleAvatarSuccess", file)
      // 上传头像事件，强制重新渲染
      this.$forceUpdate()
    },

    handleSelectChange($event, e) {
      console.log("handleSelectChange", $event, e)
      e.cashFlag && this.$emit("_handleSelectChange", $event, e)
    },

    // 上传logo
    handleLogoSuccess(res, file) {
      this.$emit("handleLogoSuccess", file)
      // 上传logo事件，强制重新渲染
      this.$forceUpdate()
    },

    // 上传照片之前
    beforeAvatarUpload(file) {
      // console.log("上传照片之前", file)
      const isJPG = file.type === "image/jpeg"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!")
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!")
      }
      return isJPG && isLt2M
    },

    // 多张照片删除
    handleRemove(file, fileList) {
      // console.log("handleRemove", file, fileList)
      this.$emit("handleRemove", file, fileList)
    },

    // 上传多张照片成功
    handleSuccess(response, file, fileList) {
      this.$emit("handleSuccess", response, file, fileList)
    },

    // 选择省市区事件
    cascaderChange(value) {
      var loc = ""
      for (let i = 0; i < value.length; i++) {
        loc += CodeToText[value[i]]
      }
      console.log("loc", loc)
      console.log("cascaderChange", value, this.selectedOptions)
    },

    // change(){
    //   this.$forceUpdate()
    // },

    // 下拉框搜索数据过滤
    handleFilter(e = "") {
      this.$emit("_handleFilter", e)
    },
  },
}
</script>

<style lang="scss" scoped>
// .common-form {
// }

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  .flex-button {
    margin-left: 20px;
  }
}
</style>