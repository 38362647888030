// import axios from "./http"

/* 
    数据请求的唯一入口
*/
import personal from "./personal"

// 导出
export default {
    personal
}