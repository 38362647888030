import Vue from "vue"

Vue.filter("NumberFormat", function (value) {
  if (!value) {
    return "0"
  }
  let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") //将整数部分逢三一断
  return intPartFormat
})

/** 字符串超长截取省略号显示 */
Vue.filter("ellipsis", function (value, vlength = 25) {
  if (!value) {
    return ""
  }
  console.log("vlength: " + vlength)
  if (value.length > vlength) {
    return value.slice(0, vlength) + "..."
  }
  return value
})

/**会员卡字典值 */
Vue.filter("cardType", function (value) {
  // console.log("cardType", value)
  if (value === 0) {
    return "现金储值卡"
  } else if (value === 1) {
    return "项目储值卡"
  } else {
    return "暂无"
  }
})
