/**
 * @description 入口文件
 */

import Vue from "vue" // vue示例
import App from "./App.vue" // 渲染app
import router from "./router" // 引入router路由
import store from "./store" // 引入vuex
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入顶部进度条
import NProgress from "nprogress"
import "nprogress/nprogress.css"

// 引入全局过滤器
import '@/utils/filter.js' 

import CommonQuery from './components/CommonQuery/CommonQuery.vue' // 查询组件
import CommonQueryFrom from './components/CommonQuery/CommonQuery-demo.vue' // 查询组件
import CommonTable from './components/ConmonTable/CommonTable.vue' // 表格组件
import CommonDialogForm from './components/CommonDialogForm/CommonDialogForm.vue' // 弹框表单组件
import CommonBreadcrumb from './components/CommonBreadcrumb/CommonBreadcrumb.vue' // 面包屑
// 注册到全局组件
Vue.component('CommonQuery', CommonQuery)
Vue.component('CommonQueryFrom', CommonQueryFrom)
Vue.component('CommonTable', CommonTable)
Vue.component('CommonDialogForm', CommonDialogForm)
Vue.component('CommonBreadcrumb', CommonBreadcrumb)

import api from "./api/api"

// 注册
Vue.use(ElementUI)

// 挂载到Vue原型
Vue.prototype.$api = api

Vue.config.devtools = true
Vue.config.productionTip = false

// // 注册全局过滤器
// import  * as filters from '@/utils/filter'
// Object.keys(filters).forEach(key => {
//   Vue.filter(key, filters[key])
// })

// 配置顶部进度条
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 1000, // 递增进度条的速度
  showSpinner: true, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from, next) => { // 每次切换页面时，调用进度条
  NProgress.start() // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  next()
})

router.afterEach(() => { // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")