<!--
* @FileDescription: 面包屑导航
* @Author: chenz
* @Date: 2021/5/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-breadcrumb">
    <el-breadcrumb separator="/">
      <template v-for="(item, index) in levelList">
        <el-breadcrumb-item
          v-if="item.name"
          :key="index"
          :to="{ path: item.path }"
        >
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "CommonBreadcrumb",

  data() {
    return {
      levelList: null,
    }
  },

  watch: {
    // 监听路由变化
    $route() {
      this.getBreadcrumb()
    },
  },

  created() {
    this.getBreadcrumb()
  },

  methods: {
    // 获取匹配到的路由
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name)
      const first = matched[0]
      // 如果第一项的name值不是home 就合并
      if (first && first.name !== "Home") {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched)
      }
      this.levelList = matched // 赋值
    },
  },
}
</script>

<style lang="scss" scoped>
.common-breadcrumb {
  margin: 10px 0;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>