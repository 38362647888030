<!--
* @FileDescription: 查询表单
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="common-query">
    <!-- 表单 -->
    <el-form :inline="true" :model="queryForm" class="form">
      <el-form-item>
        <el-input
          v-if="isOnlyAdd"
          isInput
          size="small"
          clearable
          v-model="queryForm.inputVal1"
          :placeholder="placeholder1"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-if="isOnlyAdd"
          isInput
          size="small"
          clearable
          v-model="queryForm.inputVal2"
          :placeholder="placeholder2"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="isOnlyAdd"
          icon="el-icon-search"
          type="primary"
          size="small"
          @click="query"
          >查询</el-button
        >
        <el-button
          icon="el-icon-plus"
          v-if="isAdd"
          type="primary"
          size="small"
          @click="add"
          >添加</el-button
        >
        <el-button
          v-if="isOnlyAdd"
          icon="el-icon-download"
          type="primary"
          size="small"
          @click="_export"
          >导出(不选导出全部)
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "CommonQuery",
  props: {
    // 表单
    queryForm: {
      type: Object,
      default: () => {
        return {}
      },
    },

    // input文字
    placeholder1: {
      type: String,
      default: "请输入内容",
    },

    placeholder2: {
      type: String,
      default: "请输入内容",
    },

    // 是否添加
    isAdd: {
      type: Boolean,
      default: false,
    },

    // 只有添加按钮
    isOnlyAdd: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },

  methods: {
    // 查询
    query() {
      this.$emit("_query", this.queryForm)
    },

    // 添加
    add() {
      this.$emit("_add", this.queryForm)
    },

    // 导出
    _export() {
      this.$emit("_export", this.queryForm)
    },
  },
}
</script>

<style lang="scss" scoped>
.common-query {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .form {
    flex: 1;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}
</style>